import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Transition from '@material-ui/core/Grow';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';
import Button from '@material-ui/core/Button';
import Background from 'gobo-react-components/lib/core/Background';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    color: theme.palette.white,
    fontSize: theme.typography.h2.fontSize,
  },
  text: {
    color: theme.palette.white,
  },
  title: {
    fontSize: 50,
  },
  error: {
    width: '50%',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    color: theme.palette.white,
    borderColor: theme.palette.white,
    marginLeft: theme.spacing(2),
  },
  accordion: {
    background: 'transparent',
    marginBottom: theme.spacing(2),
    maxWidth: 850,
    borderRadius: 4,
    boxShadow: 'none',
    transition: 'background 0.2s ease',
    "&.Mui-expanded":{
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.error.dark}`,
    },
    '&:before': {
      background: 'none',
    },
    '& .MuiTypography-root': {
      color: theme.palette.white,
    },
    '& .MuiAccordionSummary-root':{
      '& .MuiAccordionSummary-expandIcon':{
        color: theme.palette.white,
      }
    }
  },
}));

export const ReactError = (props) => {
  const classes = useStyles();
  const { error, resetErrorBoundary } = props;

  console.log(error);
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      minHeight='100vh'>
      <Background background='#ad0037' />
      <Transition in={true} timeout={1000} mountOnEnter unmountOnExit>
        <ErrorIcon className={classes.icon} />
      </Transition>
      <Typography
        className={clsx(classes.text, classes.title)}
        paragraph
        variant='button'>
        Something Went Wrong
      </Typography>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id='fatal-error-message'>
          <Typography variant='body1' className={classes.text}>
            The application has encountered an unknown error. Click here to see
            more details
          </Typography>
        </AccordionSummary>
        <AccordionDetails id='fatal-error-message'>
          <div>
            <Typography variant='overline' className={classes.text}>
              Details:
            </Typography>
            <Typography variant='body2' className={classes.text}>
              <code>{JSON.stringify(error.message, null, 4)}</code>
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box>
        <Button
          variant='outlined'
          onClick={() => (window.location = '/')}
          size='large'
          disableElevation
          startIcon={<HomeIcon />}
          className={classes.button}>
          Home
        </Button>
      </Box>
    </Box>
  );
};

ReactError.defaultProps = {
  error: null,
};
