import { makeStyles } from "@material-ui/core/styles";

/**
 * Material-UI Styles
 */
export default makeStyles((theme) => ({
  root: {},
  background: {
    background: theme.palette.secuneticsOrangeGradient,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -100,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  loginCard: {
    padding: theme.spacing(4),
    borderRadius: 4,
  },
  loginCardHeader: {
    "& .MuiCardHeader-title": {
      // fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h6.fontSize,
    },
    "& .MuiCardHeader-subheader": {
      visibility: "visible",
      opacity: 0,
      transition: "0.3s",
      fontWeight: theme.typography.fontWeightBold,
      "&.visible": {
        transition: "0.3s",
        visibility: "visible",
        opacity: 1,
      },
    },
  },
  button: {},
  loginCardContent: {},
  loginCardActions: {
    flexDirection: "row-reverse",
  },
  descriptionRoot: {
    background: theme.palette.secuneticsBlueGradient,
    padding: theme.spacing(10),
    boxShadow: theme.shadows[10],
  },
  descriptionBody: {},
}));