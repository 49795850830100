import React from "react";

export const BannerContent = (props) => {
  return (
    <ul>
      <li>
        You are accessing a U.S. Government computer/information system. Access
        to this system is restricted to U.S. Government-authorized use only.
      </li>
      <li>
        Unauthorized or improper use of this system may result in disciplinary
        action, as well as civil and criminal penalties.
      </li>
      <li>
        By using this information system, you understand and consent to the
        following:
        <ul>
          <li>
            You have no reasonable expectation of privacy regarding any
            communications or data transiting or stored on this information
            system.
          </li>
          <li>
            At any time, the government may monitor, audit, intercept, search
            and seize any communication or data transiting or stored on this
            information system.
          </li>
          <li>
            Any communications or data transiting or stored on this information
            system may be disclosed or used for any lawful government purpose.
          </li>
        </ul>
      </li>
    </ul>
  );
};
