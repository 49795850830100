import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { ErrorBoundary } from "react-error-boundary";
import ReactError from "components/ReactError";
import theme from "gobo-react-components/lib/styles/theme";
import "App.css";
import "firebase/auth";
import AuthHandler from "components/AuthHandler";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from 'material-ui-confirm';


const useStyles = makeStyles(() => ({
  success: {
    // borderRadius: 10,
    padding: 10,
    backgroundColor: `${theme.palette.success.main} !important`,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.success.dark,
    // borderWidth: 2,
    // borderStyle: 'solid',
    // borderColor: theme.palette.success.light,
    // color: `${theme.palette.success.light} !important`,
  },
  error: {
    // borderRadius: 10,
    padding: 10,
    backgroundColor: `${theme.palette.error.main} !important`,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.error.dark,
    // boxShadow: `0 0 10px 10px ${tinycolor(theme.palette.error.light)
    //   .setAlpha(0.3)
    //   .toRgbString()}`,
    // borderWidth: 2,
    // borderColor: theme.palette.background.paper,
    // color: `${theme.palette.error.light} !important`,
  },
  info: {
    // borderRadius: 10,
    padding: 10,
    backgroundColor: `${theme.palette.info.main} !important`,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.info.dark,
    // borderWidth: 2,
    // borderStyle: 'solid',
    // borderColor: theme.palette.info.light,
    // color: `${theme.palette.info.light} !important`,
  },
  warning: {
    // borderRadius: 10,
    padding: 10,
    backgroundColor: `${theme.palette.warning.main} !important`,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.warning.dark,
    // borderWidth: 2,
    // borderStyle: 'solid',
    // borderColor: theme.palette.warning.light,
    // color: `${theme.palette.warning.light} !important`,
  },
}));

const App = (props) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info,
        }}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <ErrorBoundary FallbackComponent={ReactError}>
            <ConfirmProvider>
              <AuthHandler />
            </ConfirmProvider>
        </ErrorBoundary>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
