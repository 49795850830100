import React from "react";
import _ from "lodash";
import clsx from "clsx";
import { useSessionStorage } from "react-use";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Zoom from "@material-ui/core/Zoom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import TextField from "gobo-react-components/lib/core/TextField";
import GoboLogo from "gobo-react-components/lib/core/GoboLogo";
import firebase from "firebase/app";
import Login from "./components/Login";
import PasswordReset from "./components/PasswordReset";
import MFA from "./components/MFA";
import BannerContent from "./components/BannerContent";
import "firebase/auth";
import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import * as ciap from "gcip-iap";
import useStyles from "./styles";

const customErrorCodes = (error) => {
  if (error === null || error === undefined) {
    return null;
  }
  if (typeof error === "string") {
    error = {
      code: "default",
      message: error,
    };
  }

  switch (error.code) {
    case "restart-process":
      error.message =
        "Authentication Token has expired. Please visit the original URL that brought you here.";
      error.refresh = true;
      break;
    case "auth/missing-phone-number":
      error.message =
        "Your account has not been configured with a valid phone number. Please contact administrator to update your account";
      error.refresh = true;
      break;
    case "auth/invalid-phone-number":
      error.message =
        "The phone number associated with your account is invalid. Please contact administrator to correct this";
      error.refresh = true;
      break;
    case "auth/missing-verification-code":
      error.message = "Please enter a valid verification code";
      break;
    case "auth/invalid-verification-code":
      error.message = "Invalid verification code";
      break;
    case "auth/code-expired":
      error.message = "The verification code has expired. Please try again";
      error.refresh = true;
      break;
    case "auth/multi-factor-auth-required":
      error.message =
        "You need to enable multi-factor authentication to continue";
      error.refresh = true;
      break;
    case "auth/quota-exceeded":
      error.message = "Too many tries";
      error.refresh = true;
      break;
    case "auth/missing-verification-code":
      error.message =
        "Please enter the verification code that was sent to your phone number";
      break;
    case "auth/unverified-email":
      error.message = "Your email is not verified";
      error.refresh = true;
      break;
    case "auth/invalid-email":
    case "auth/user-not-found":
    case "auth/wrong-password":
      error.message = "Invalid email address or password";
      error.refresh = false;
      break;
    default:
      error.message = error.message;
      error.refresh = true;
  }
  return error;
};

/**
 * AuthHandler component that renders the Login Page and uses IAP and Firebase modules to
 * login the user to a GCP IAP instance
 * @param {*} props
 */
export const AuthHandler = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  /**
   * The MFA verification code which the user will enter provided they are enrolled to MFA
   * @type {[string | null, React.Dispatch<any>]}
   */
  const [mfaVerificationCode, setMfaVerificationCode] = React.useState("");
  /**
   * The MFA verification code which the user will enter to enroll themselves to MFA
   * @type {[string | null, React.Dispatch<any>]}
   */
  const [
    mfaEnrollmentVerificationCode,
    setMfaEnrollmentVerificationCode,
  ] = React.useState("");

  /**
   * The URL from where this page was redirected to
   * @type {[string | null, React.Dispatch<any>]}
   */
  const [originalUrl, setOriginalUrl] = React.useState(null);
  /**
   * The value of the email field. This email will be used for login, and also for password reset
   * @type {[string, React.Dispatch<any>]}
   */
  const [email, setEmail] = React.useState("");
  /**
   * The value of the password field. This password value will be used for login
   * @type {[string, React.Dispatch<any>]}
   */
  const [password, setPassword] = React.useState("");
  /**
   * Boolean value to track progress of logging in as well as password reset
   * @type {[boolean, React.Dispatch<boolean>]}
   */
  const [buttonLoading, setButtonLoading] = React.useState(false);
  /**
   * An error object used to indicated errors
   * @type {[{code: string, message: string} | null, React.Dispatch<{
   * code: string, message: string}>]}
   */
  const [error, setError] = React.useState((function () {})());
  /**
   * The "mode" to track what operation is currently being performed.
   * Either `LOG IN` or `PASSWORD RESET`
   * This will be used as the title of the login UI card too
   * @type {['LOG_IN' | 'PASSWORD_RESET' | 'MFA_VERIFICATION' | 'MFA_ENROLLMENT',
   * React.Dispatch<'LOG_IN' | 'PASSWORD_RESET' | 'MFA_VERIFICATION' | 'MFA_ENROLLMENT'>]}
   */
  const [mode, setMode] = React.useState("LOG_IN");
  /**
   * Variable to track whether the Login Page has been loaded at least once in
   * the current tab ( uses browser session storage to track this variable )
   * @type {[boolean, React.Dispatch<boolean>]}
   */
  const [loginLoaded, setLoginLoaded] = useSessionStorage(
    "gobo-login-loaded",
    false
  );

  /**
   * A function reference to store the actual function used to login the user
   * This will be set by IAP down in `startSignIn` function
   * @type {[(email: string, password: string) => void, React.Dispatch<()=>function>]}
   */
  const [signInHandler, setSignInHandler] = React.useState((func) => func);
  /**
   * A function reference to store the actual function used to send the password-reset link
   * to the provided email. This will be set in the `getAuth` function below
   * @type {[(email:string) => Promise<string>, React.Dispatch<()=>function>]}
   */
  const [passwordResetHandler, setPasswordResetHandler] = React.useState(
    (func) => func
  );

  const [mfaVerificationTries, setMfaVerificationTries] = React.useState(0);

  /**
   * A function reference to store the actual function used to confirm the mfaverification for
   * the user trying to log in
   * @type {[(verificationCode: string)=>void, React.Dispatch<()=>function>]}
   */
  const [mfaVerificationHandler, setMfaVerificationHandler] = React.useState(
    (func) => func
  );

  /**
   * A function reference to store the actual function used to confirm the mfaverification for
   * the user trying to log in
   * @type {[(verificationCode: string)=>void, React.Dispatch<()=>function>]}
   */
  const [mfaEnrollmentHandler, setMfaEnrollmentHandler] = React.useState(
    (func) => func
  );

  /**
   * This function take a reference to a function as a paramter,
   * which will be set as state variable `signInHandler`
   * @param {function} signInFunc
   */
  const updateSignInHandler = (signInFunc) => {
    setSignInHandler(() => signInFunc);
  };

  /**
   * This function take a reference to a function as a paramter,
   * which will be set as state variable `passwordResetHandler`
   * @param {function} signInFunc
   */
  const updatePasswordResetHandler = (passwordResetFunc) => {
    setPasswordResetHandler(() => passwordResetFunc);
  };

  /**
   * This function take a reference to a function as a parameter,
   * which will be set as state variable `mfaVerificationHandler`
   * @param {function} mfaVerificationFunc
   */
  const updateMfaVerificationHandler = (mfaVerificationFunc) => {
    setMfaVerificationHandler(() => mfaVerificationFunc);
  };

  /**
   * This function take a reference to a function as a parameter,
   * which will be set as state variable `mfaEnrollmentHandler`
   * @param {function} mfaEnrollmentFunc
   */
  const updateMfaEnrollmentHandler = (mfaEnrollmentFunc) => {
    setMfaEnrollmentHandler(() => mfaEnrollmentFunc);
  };

  const redirectToHomePage = (url = null) => {
    window.location.href = url || originalUrl;
  };
  /**
   * @important Mandatory IAP Function. Do Not Rename!
   * @summary A function that gets the auth object from `firebase`
   * A function
   * @param {string} apiKey The API key that is generated by IAP
   * @param {string} tenantId
   */
  const getAuth = (apiKey, tenantId) => {
    let auth = null;
    try {
      auth = firebase.app(tenantId || undefined).auth();
      // Tenant ID should be already set on initialization below.
    } catch (e) {
      const app = firebase.initializeApp(
        {
          apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        },
        tenantId || "[DEFAULT]"
      );
      auth = app.auth();
      updatePasswordResetHandler((email) => auth.sendPasswordResetEmail(email));
      auth.tenantId = tenantId || null;
    }
    return auth;
  };

  /**
   *  A function to update the `error` state variable
   * @param {{ code?: string; message?: string; retry?: any }} errorObj
   */
  const updateError = (errorObj) => {
    errorObj = customErrorCodes(errorObj);
    setError(errorObj);
    if (errorObj?.code === "restart-process") {
      redirectToHomePage();
    }
  };

  /**
   * Get reCaptchaVerifier
   * @param {string} elementId Id of the html element, typically the login button
   */
  const getRecaptchaVerifier = (elementId) => {
    return new firebase.auth.RecaptchaVerifier(elementId, {
      size: "invisible",
      callback: (response) => {},
      "expired-callback": () => {
        window.grecaptcha.reset();
      },
    });
  };

  /**
   *
   * @param {firebase.User} user
   */
  const processUser = (user) => {
    return new Promise((resolve, reject) => {
      // Check if user has phone number

      var userPhoneNumber = user.phoneNumber;

      // If user does not have phone number, then reject
      if (!userPhoneNumber) {
        reject({
          message:
            "Your account does not have any phone number associated with it. Please contact administrator to add a phone number to your account and try again.",
        });
        // reject(new Error({
        //   message: "Your account needs a phone number associated with it",
        // }));
      }
      if (userPhoneNumber) {
        // Check if user is already enrolled
        if (
          user.multiFactor.enrolledFactors?.filter(
            (factor) =>
              factor.factorId ===
              firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
          )?.[0]
        ) {
          resolve(user);
        } else {
          setMode("MFA_ENROLLMENT");
          confirm({
            title: "Enable Multifactor Authentication",
            description:
              "Enroll yourself to multifactor authentication? Standard SMS rates apply",
          })
            .then(() => {
              setButtonLoading(true);
              user.multiFactor
                .getSession()
                .then((multiFactorSession) => {
                  const phoneInfoOptions = {
                    phoneNumber: userPhoneNumber,
                    session: multiFactorSession,
                  };
                  const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
                  return phoneAuthProvider.verifyPhoneNumber(
                    phoneInfoOptions,
                    getRecaptchaVerifier("recaptcha-container")
                  );
                })
                .then((verificationId) => {
                  setButtonLoading(false);
                  updateMfaEnrollmentHandler((verificationCode) => {
                    const cred = firebase.auth.PhoneAuthProvider.credential(
                      verificationId,
                      verificationCode
                    );
                    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
                      cred
                    );

                    return user.multiFactor.enroll(
                      multiFactorAssertion,
                      "Personal Phone Number"
                    );
                  });
                })
                .catch((err) => {
                  window.grecaptcha?.reset();
                  reject(err);
                });
            })
            .catch(() => {
              reject({
                code: "auth/multi-factor-auth-required",
                message: "Please enroll into MFA",
              });
            });
        }
      }
    });
  };

  /**
   * @important Mandatory IAP Function. Do Not Rename!
   * @summary A Promise that doesn't actually sign in the user directly, but updates the
   * state variable `signInHandler` which will be called by the appropriate event handler
   * @param {firebase.auth.Auth} auth
   */
  const startSignIn = (auth) => {
    return new Promise((signInResolve, signInReject) => {
      var resolver;
      // Set SignIn Function
      updateSignInHandler(
        /**
        @param {string} email
        @param {string} password
        **/
        (email, password) => {
          auth
            .signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
              updateError(null);
              signInResolve(userCredential);
            })
            .catch((err) => {
              /**
               * The following error `auth/multi-factor-auth-required` is an expected error for
               * users who have MFA enabled
               * We want to prompt the user for the verification code at this stage
               */
              if (err?.code == "auth/multi-factor-auth-required") {
                updateError(null);
                setButtonLoading(false);
                resolver = err.resolver;
                // Get Phone Resolver Hint
                const multiFactorHint = resolver.hints?.filter(
                  (rh) =>
                    rh.factorId ===
                    firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
                )?.[0];
                if (multiFactorHint) {
                  setMode("MFA_VERIFICATION");

                  confirm({
                    title: "Multi-factor authentication",
                    description: `A verification code will be sent to your phone number ending with ${multiFactorHint.phoneNumber}. Standard SMS rates apply`,
                  })
                    .then(() => {
                      setButtonLoading(true);
                      const phoneVerifier = new firebase.auth.PhoneAuthProvider().verifyPhoneNumber(
                        {
                          multiFactorHint,
                          session: resolver.session,
                        },
                        getRecaptchaVerifier("recaptcha-container")
                      );

                      return phoneVerifier;
                    })
                    .then((verificationId) => {
                      setButtonLoading(false);
                      updateMfaVerificationHandler((verificationCode) => {
                        const cred = firebase.auth.PhoneAuthProvider.credential(
                          verificationId,
                          verificationCode
                        );
                        const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
                          cred
                        );
                        return resolver.resolveSignIn(multiFactorAssertion);
                      });
                    })
                    .catch((mfaConfirmationError) => {
                      signInReject(
                        mfaConfirmationError || {
                          code: "auth/multi-factor-auth-required",
                        }
                      );
                    });
                }
              } else {
                setButtonLoading(false);
                auth.signOut();
                signInReject(err);
              }
            })
            .finally(() => {});
        }
      );
      return false;
    });
  };

  /**
   * @important Mandatory IAP Function. Do Not Rename!
   * @summary An error handler used by IAP
   * @param {any} error
   */
  const handleError = (error) => {
    setButtonLoading(false);
    updateError(error);
  };

  const signOut = () => {};

  /**
   * @important Mandatory IAP Function. Do Not Rename!
   * @summary Not really sure what this does or if it is even necessary but it is required
   * during IAP initialization
   */
  const completeSignOut = () => {
    signOut();
    return Promise.resolve();
  };

  React.useEffect(() => {
    /**
     * Set Login Loaded to true.
     * This indicates that the login page has loaded for the current session ( browser tab )
     * The animation will not play after this is set to true
     */
    setLoginLoaded(true);

    /**
     * Initialize ciapInstance with some handler functions
     */
    const ciapInstance = new ciap.Authentication({
      getAuth: getAuth,
      startSignIn: startSignIn,
      completeSignOut: completeSignOut,
      handleError: handleError,
      processUser: processUser,
    });

    /**
     * Get the original URL from where this login page was reached
     * For dev instances, it is usually `https://gobofw.ue.r.appspot.com/`
     * and for the production instance it is `https://gobo.secunetics.com` at the
     * time of writing this
     */
    ciapInstance
      ?.getOriginalURL()
      .then((url) => {
        setOriginalUrl(url);
        /**
         * Start the ciapInstance
         * From the documentation it doesn't seem clear whether start() is a Promise or not,
         * but there are specific instances where the `.catch(()=>{...})` block executes so maybe it is?
         */
        ciapInstance.start().catch((err) => {
          firebase
            .auth()
            .signOut()
            .then(() => {
              const customError = customErrorCodes(err);
              confirm({
                title: "Error",
                description: !!err ? customError.message : "Unable to log in",
                confirmationText: "Retry",
                cancellationButtonProps: {
                  style: {
                    display: "none",
                  },
                },
              }).then(() => {
                if (customError.refresh === true) {
                  redirectToHomePage(url);
                }
              });
            })
            .catch(() => {
              redirectToHomePage(url);
            })
            .finally(() => {
              updateError(err);
            });
        });
      })
      .catch((err) => {
        setOriginalUrl(null);
      });
  }, []);

  /**
   * Triggers when the user clicks the login button
   * This function doesn't actually log in the user yet, it justs
   * opens the banner, upon the acceptance of which the login in process starts
   * @param {React.FormEvent} evt
   */
  const onLoginSubmit = (evt) => {
    evt.preventDefault();
    confirm({
      title: "Warning",
      description: <BannerContent />,
    })
      .then(() => {
        signInHandler(email, password);
      })
      .catch(() => {});
  };

  /**
   * Triggers when the user clicks the Password Reset button.
   * @param {React.FormEvent} evt
   */
  const onPasswordResetSubmit = (evt) => {
    evt.preventDefault();
    setButtonLoading(true);
    passwordResetHandler(email)
      .then((res) => {
        enqueueSnackbar(`Password reset link sent to ${email}`, {
          variant: "success",
        });
        setError(null);
        setMode("LOG IN");
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  /**
   * @param {React.FormEvent} evt
   */
  const onMfaVerificationSubmit = (evt) => {
    evt.preventDefault();
    setButtonLoading(true);
    mfaVerificationHandler(mfaVerificationCode)
      .then((userCredential) => {
        updateError(null);
        setButtonLoading(true);
        redirectToHomePage();
      })
      .catch((mfaResolverError) => {
        setButtonLoading(false);
        setMfaVerificationTries(mfaVerificationTries + 1);
        updateError(mfaResolverError);
      })
      .finally(() => {});
  };

  /**
   * @param {React.FormEvent} evt
   */
  const onMfaEnrollmentSubmit = (evt) => {
    evt.preventDefault();
    setButtonLoading(true);
    mfaEnrollmentHandler(mfaEnrollmentVerificationCode)
      .then((res) => {
        confirm({
          title: "Success",
          cancellationButtonProps: {
            style: {
              display: 'none'
            }
          },
          description:
            "You have been successfully enrolled into multi-factor authentication. Click OK to continue.",
        }).then(() => {
          redirectToHomePage();
        });
      })
      .catch((err) => {
        updateError(err);
        setButtonLoading(false);
      })
      .finally(() => {});
  };

  return (
    <div className="main-container">
      <div id="recaptcha-container" />
      <div id="sign-in-ui-container">
        <form onSubmit={(evt) => {}} />
        <Grid
          container
          justify="center"
          alignContent="center"
          className={classes.root}>
          <Zoom in={true} timeout={loginLoaded ? 0 : 400}>
            <Grid item className={classes.loginRoot} xs={12} lg={4} sm={12}>
              <Fade in={true} timeout={loginLoaded ? 0 : 800}>
                <Box marginTop={10} p={5}>
                  <Card elevation={16} className={classes.loginCard}>
                    <Box display="flex" justifyContent="center">
                      <GoboLogo size={50} />
                    </Box>
                    <CardHeader
                      className={classes.loginCardHeader}
                      titleTypographyProps={{
                        variant: "overline",
                        display: "block",
                        align: "center",
                      }}
                      title={
                        mode === "LOG_IN"
                          ? "LOG IN"
                          : mode === "PASSWORD_RESET"
                          ? "RESET PASSWORD"
                          : mode === "MFA_VERIFICATION"
                          ? "VERIFY"
                          : mode === "MFA_ENROLLMENT"
                          ? "ENABLE MULTIFACTOR AUTHENTICATION"
                          : ""
                      }
                      subheader={error?.message}
                      subheaderTypographyProps={{
                        variant: "caption",
                        color: "error",
                        align: "center",
                        className: clsx(
                          "MuiCardHeader-subheader",
                          error && "visible"
                        ),
                      }}
                    />
                    <CardContent className={classes.loginCardContent}>
                      {mode === "LOG_IN" && (
                        <Login
                          onSubmit={onLoginSubmit}
                          email={email}
                          password={password}
                          emailProps={{
                            onChange: (evt) => setEmail(evt.target.value),
                            error: !!error,
                          }}
                          passwordProps={{
                            onChange: (evt) => setPassword(evt.target.value),
                            error: !!error,
                          }}
                          buttonProps={{
                            id: "sign-in-button",
                            loading: buttonLoading,
                            size: "large",
                          }}></Login>
                      )}
                      {mode === "PASSWORD_RESET" && (
                        <PasswordReset
                          onSubmit={onPasswordResetSubmit}
                          email={email}
                          emailProps={{
                            onChange: (evt) => setEmail(evt.target.value),
                            error: !!error,
                          }}
                          buttonProps={{
                            loading: buttonLoading,
                          }}
                        />
                      )}
                      {mode === "MFA_VERIFICATION" && (
                        <MFA
                          onSubmit={onMfaVerificationSubmit}
                          code={mfaVerificationCode}
                          codeProps={{
                            onChange: (evt) =>
                              setMfaVerificationCode(evt.target.value),
                            error: !!error,
                          }}
                          buttonProps={{
                            id: "mfa-verification-button",
                            loading: buttonLoading,
                            size: "large",
                          }}
                        />
                      )}
                      {mode === "MFA_ENROLLMENT" && (
                        <MFA
                          onSubmit={onMfaEnrollmentSubmit}
                          code={mfaEnrollmentVerificationCode}
                          codeProps={{
                            onChange: (evt) =>
                              setMfaEnrollmentVerificationCode(
                                evt.target.value
                              ),
                            error: !!error,
                          }}
                          buttonProps={{
                            id: "mfa-enrollment-button",
                            loading: buttonLoading,
                            size: "large",
                          }}
                        />
                      )}
                    </CardContent>
                    {/* <CardActions className={classes.loginCardActions}>
                      {mode === "LOG_IN" && (
                        <LoadingButton
                          size="small"
                          onClick={() => {
                            setError(null);
                            setMode("PASSWORD_RESET");
                          }}>
                          Forgot Password?
                        </LoadingButton>
                      )}
                      {mode === "PASSWORD_RESET" && (
                        <LoadingButton
                          size="small"
                          onClick={() => {
                            setError(null);
                            setMode("LOG_IN");
                          }}>
                          Log In Instead
                        </LoadingButton>
                      )}
                    </CardActions> */}
                  </Card>
                </Box>
              </Fade>
            </Grid>
          </Zoom>
        </Grid>
      </div>
    </div>
  );
};
