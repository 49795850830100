import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "gobo-react-components/lib/core/TextField";
import Button from "gobo-react-components/lib/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

export const PasswordReset = (props) => {
  const classes = useStyles();
  const { email, emailProps, buttonProps, onSubmit } = props;
  return (
    <Grid onSubmit={onSubmit} container component="form" spacing={2}>
      <Grid item xs={12}>
        <TextField
          autoFocus
          name="email"
          variant="outlined"
          fullWidth
          required
          label="Email"
          value={email}
          {...emailProps}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          size="large"
          fullWidth
          type="submit"
          color="secondary"
          className={classes.button}
          disableElevation
          variant="contained"
          {...buttonProps}>
          {buttonProps?.label || "SEND RESET LINK"}
        </Button>
      </Grid>
    </Grid>
  );
};
